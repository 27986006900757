var mixin = {
    data() {
        return {
            weekdays: ["Må", "Ti", "On", "To", "Fr", "Lö", "Sö"],
            months: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
        }
    },
}

export default mixin
